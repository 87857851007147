<template>
  <div class="container project margin-top-40">
    <div class="row margin-bottom-40">
        <div class="col-sm">
            <h1 class="bitter-bold">
                404 - Page Not Found
            </h1>
            <h2 class="bitter-bold">
                This is not the page you're looking for.
            </h2>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Error404',
};
</script>
